(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("normalize-url"), require("quill-delta"));
	else if(typeof define === 'function' && define.amd)
		define(["normalize-url", "quill-delta"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("normalize-url"), require("quill-delta")) : factory(root["normalize-url"], root["quill-delta"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__876__, __WEBPACK_EXTERNAL_MODULE__850__) {
return 